import { Link } from "gatsby";
import React, { useEffect } from "react";
import theme from "../styles/theme";

const Navbar = ({ location, headerType }) => {
  const color = headerType === "transparent" ? "#fff" : theme.pink;

  useEffect(() => {
    let el = document.querySelector(`[href="${location.pathname}"]`);
    if (!el) return;
    el.classList.add("active");
  }, []);

  return (
    <nav
      className="navbar"
      css={`
        display: flex;
        padding-left: 20px;
        align-items: center;
        margin-left: auto;
        .linkAlone {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .dropbtn {
          border: none;
          font-size: 16px;
          padding-top: 10px;
          padding-bottom: 10px;
          color: ${color};
          margin-top: 0;
          svg {
            margin-left: 10px;
          }
        }
        .dropdown {
          display: flex;
          position: relative;
        }
        a {
          font-size: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          padding-left: 20px;
          padding-right: 20px;
          color: ${color};
          font-weight: 700;
        }
        .dropdown-content {
          display: none;
          position: absolute;
          top: 32px;
          left: 20px;
          padding: 0;
          min-width: 280px;
          background-color: #474747;
          flex-direction: column;
          border-radius: 20px;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
          z-index: 1;
          text-align: left;
          align-items: flex-start;
          overflow: hidden;
          a:first-child {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
          }
          a:last-child {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          a {
            justify-content: flex-start;
            padding: 31px 35px;
            color: #fff;
            width: 100%;
            box-sizing: border-box;
          }
          a:hover {
            background: #656565;
          }
        }
        .dropdown:hover .dropdown-content {
          display: flex;
        }
        .button.cta {
          margin-left: 20px;
          color: ${color};
          border-color: ${color};
          min-width: 120px;
        }
        .dropdown-content .active {
          background: #656565;
        }
        .linkAlone.active span {
          border-bottom: 2px solid ${theme.pink};
        }
        .dropdown-content a:not(:last-child) {
          box-shadow: 0px 30px 3px -30px ${theme.pink};
        }
        .dropdown-content.services span {
          margin-left: 25px;
        }
      `}
    >
      <div class="dropdown">
        <button class="dropbtn">
          Platform
          <svg
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={color}
            />
          </svg>
        </button>
        <div class="dropdown-content services">
          <Link to="/platform">Omniplatform</Link>
          <Link to="/services/management-console">
            <span>Management Console</span>
          </Link>
          <Link to="/services/app">
            <span>App</span>
          </Link>
          <Link to="/services/pos">
            <span>PoS</span>
          </Link>
          <Link to="/services/kiosks">
            <span>Kiosks</span>
          </Link>
          <Link to="/services/order-management">
            <span>Order Management</span>
          </Link>
          <Link to="/services/webshop">
            <span>Webshop</span>
          </Link>
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn">
          Features
          <svg
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={color}
            />
          </svg>
        </button>
        <div class="dropdown-content services">
          <Link to="/features">
            <span>Mobile App</span>
          </Link>
          <Link to="/pos-features">
            <span>PoS</span>
          </Link>
          <Link to="/kiosk-features">
            <span>Kiosk</span>
          </Link>
          <Link to="/yorma-features">
            <span>Order Management</span>
          </Link>
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn">
          Industries
          <svg
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={color}
            />
          </svg>
        </button>
        <div class="dropdown-content">
          <Link to="/restaurants">Restaurants</Link>
          <Link to="/theme-parks">Theme Parks</Link>
        </div>
      </div>
      <Link className="linkAlone" to="/references">
        <span>References</span>
      </Link>
      <div class="dropdown">
        <button class="dropbtn">
          More
          <svg
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={color}
            />
          </svg>
        </button>
        <div class="dropdown-content">
          <Link to="/news">News</Link>
          <Link to="/about">About</Link>
          <Link to="/guides-and-docs">Guides & Docs</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>

      <Link className="button cta" to="/contact">
        Book demo
      </Link>
    </nav>
  );
};

export default Navbar;
