const theme = {
  pink: "#E00084",
  grey: "#474747",
  blue: "#000154",
  max400: "@media(max-width: 400px)",
  max500: "@media(max-width: 500px)",
  max600: "@media(max-width: 600px)",
  max641: "@media(max-width: 641px)",
  mobile: "@media(max-width: 600px)",
  max700: "@media(max-width: 700px)",
  max820: "@media(max-width: 820px)",
  max900: "@media(max-width: 900px)",
  tablet: "@media(max-width: 900px)",
  max960: "@media(max-width: 960px)",
  max1000: "@media(max-width: 1000px)",
  max1100: "@media(max-width: 1100px)",
  max1140: "@media(max-width: 1140px)",
  max1200: "@media(max-width: 1200px)",
  laptop: "@media(max-width: 1200px)",
  max1300: "@media(max-width: 1300px)",
  max1400: "@media(max-width: 1400px)",
  max1500: "@media(max-width: 1500px)",
  max1600: "@media(max-width: 1600px)",
  windowPaddingX: `padding-left: 20px; 
                  padding-right: 20px;
                  @media(max-width: 600px) {
                    padding-left: 10px;
                    padding-right: 10px;
                   }`,
  fullWidth: `margin-left: calc(-100vw / 2 + 1600px / 2);
                 margin-right: calc(-100vw / 2 + 1600px / 2);
                 @media(max-width: 1600px) {
                  margin-left: 0;
                  margin-right: 0;
                 }
  `,
};

export default theme;
