import { Link } from "gatsby";
import React, { useState } from "react";
import theme from "../styles/theme";

const MobileMenu = ({ headerType }) => {
  const [showServices, setShowServices] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const [showYonoton, setShowYonoton] = useState(false);

  return (
    <nav
      className="navbar"
      css={`
        background: white;
        padding-left: 80px;
        padding-right: 10px;
        padding-bottom: 20px;
        position: fixed;
        max-height: 90%;
        overflow-y: scroll;
        box-shadow: 0px 5px 9px -4px rgba(0, 0, 0, 0.13);
        ${theme.tablet} {
          padding-left: 40px;
        }
        ${theme.mobile} {
          padding-left: 20px;
        }
        button.groupTitle {
          color: ${theme.pink};
          font-weight: 700;
          margin-top: 4px;
          margin-bottom: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 0;
          height: auto;
          border: none;
          align-items: center;
          svg {
            margin-left: 10px;
          }
        }
        .linkGroup {
          position: relative;
          &.alone a {
            padding-left: 0;
          }
          .linksWrapper {
            height: 0;
          }
          .linksContent {
            overflow: hidden;
          }
        }
        .linkGroup a {
          font-size: 16px;
          display: flex;
          align-items: center;
          margin-top: 4px;
          margin-bottom: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 30px;
          padding-right: 20px;
          color: ${theme.pink};
          font-weight: 700;
          justify-content: flex-start;
        }
        .dropdown:hover .dropdown-content {
          display: flex;
        }
        .button.cta {
          margin-left: 20px;
          color: ${theme.pink};
          border-color: ${theme.pink};
          min-width: 160px;
        }
        .groupTitle {
          svg {
            transition: transform 0.4s ease-in-out;
          }
          svg.carretTurn {
            transform: rotate(180deg);
          }
        }
        .links.services {
          > a:not(:first-child) {
            //padding-left: 40px !important;
          }
        }
      `}
    >
      <div class="linkGroup">
        <button
          class="groupTitle"
          onClick={() => setShowServices(!showServices)}
        >
          Platform
          <svg
            className={showServices && "carretTurn"}
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={theme.pink}
            />
          </svg>
        </button>
        {showServices && (
          <div className="links services">
            <Link style={{ paddingLeft: 0 }} to="/platform">
              Omniplatform
            </Link>
            <Link to="/services/management-console">Management Console</Link>
            <Link to="/services/app">App</Link>
            <Link to="/services/pos">PoS</Link>
            <Link to="/services/kiosks">Kiosks</Link>
            <Link to="/services/order-management">Order Management</Link>
            <Link to="/services/webshop">Webshop</Link>
          </div>
        )}
      </div>
      <div class="linkGroup">
        <button
          class="groupTitle"
          onClick={() => setShowFeatures(!showFeatures)}
        >
          Features
          <svg
            className={showFeatures && "carretTurn"}
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={theme.pink}
            />
          </svg>
        </button>
        {showFeatures && (
          <div className="links services">
            <Link to="/features">Mobile App</Link>
            <Link to="/pos-features">PoS</Link>
            <Link to="/kiosk-features">Kiosk</Link>
            <Link to="/yorma-features">Order management</Link>
          </div>
        )}
      </div>
      <div class="linkGroup">
        <button
          class="groupTitle"
          onClick={() => setShowIndustries(!showIndustries)}
        >
          Industries
          <svg
            className={showIndustries && "carretTurn"}
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={theme.pink}
            />
          </svg>
        </button>
        {showIndustries && (
          <div className="links">
            <Link to="/restaurants">Restaurants</Link>
            <Link to="/theme-parks">Theme Parks</Link>
          </div>
        )}
      </div>

      <div className="linkGroup alone">
        <Link to="/references">References</Link>
      </div>

      <div class="linkGroup">
        <button class="groupTitle" onClick={() => setShowYonoton(!showYonoton)}>
          More
          <svg
            className={showYonoton && "carretTurn"}
            width="13"
            height="10"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.95402 11.0607L13.719 3.68185C14.5441 2.40399 13.556 0.77643 11.9549 0.77643L2.42507 0.776431C0.824038 0.776431 -0.164133 2.40399 0.661052 3.68185L5.42598 11.0607C6.22593 12.2995 8.15407 12.2995 8.95402 11.0607Z"
              fill={theme.pink}
            />
          </svg>
        </button>
        {showYonoton && (
          <div className="links">
            <Link to="/news">News</Link>
            <Link to="/about">About</Link>
            <Link to="/guides-and-docs">Guides & Docs</Link>
            <Link to="/contact">Contact</Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default MobileMenu;
